// make and import a models
import { Injectable } from '@angular/core';
import { Subject, of, Observable,AsyncSubject} from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { API, Auth } from 'aws-amplify';
import { NullableLong } from 'aws-sdk/clients/elasticbeanstalk';
import { Router } from '@angular/router';


//const axios = require('axios');
// export interface session{
//   cidr_removed_at:string;
//   client_pic:string;
//   created_at:string;
//   ewe_breed:string;
//   ewe_category:string;
//   session_id:string;
//   label:string;
//   location:{
//     country_code:string;
//     locality:string;
//     open_location_code:string;
//     postal_code:string;
//     region:string;
//   }
//   nutrition_status:string;
//   pmsg_dose:string;
//   xylazine_dose:string;
// }

const headers = {
  'Access-Control-Allow-Origin' : '*',
  'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
  'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  'accept':'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3',
  'accept-encoding':'gzip, deflate, br'
}

let groupIndex=[];


@Injectable({providedIn: 'root'})
export class AiService{

    private sessions: any[];
    private session;
    private groups;
    private group;
    private scans;
    private scan;

    private sessionsUpdated = new Subject<any[]>();
    private sessionUpdated = new Subject();
    private groupsUpdated = new Subject();
    private groupUpdated = new Subject();
    private scansUpdated = new Subject();
    private scanUpdated = new Subject();




    constructor(
      private http:HttpClient,
      public router: Router
      ){}


   getSessions(){
     let error;
    API.get("MyAPI",'/sessions',{})
    .then(response => {
      this.sessions=response;
      this.sessionsUpdated.next([...this.sessions]);
    }
    ).catch(err => {
      error=err;

    }).finally(()=>{
      if(error){
        this.sessions=[{session_id:null}];
      }
      this.sessionsUpdated.next([...this.sessions]);
    });
  }

  getSessionsObs(){
    return this.sessionsUpdated.asObservable();
  }

  getSession(sessionId){
    API.get("MyAPI",'/sessions/'+sessionId,{})
    .then(response => {
      this.session=response;
      this.sessionUpdated.next({...this.session});
    }
    ).catch(error => {
      console.log(error);
      this.router.navigate(['*']);
    });
  }

  getSessionObs(){
    return this.sessionUpdated.asObservable();
  }


  getGroups(sessionId):any{
    let error;
    API.get("MyAPI",'/sessions/'+sessionId+'/groups',{})
    .then(response => {
      this.groups=response;
    }
    ).catch(err => {
      error=err;

    }).finally(()=>{
      if(error){
        this.groups=[{group_id:null}];
      }
      this.groupsUpdated.next([...this.groups]);
    });

  }

  getGroupsObs(){
    return this.groupsUpdated.asObservable();
  }


  getGroup(sessionId,groupId){
    API.get("MyAPI",'/sessions/'+sessionId+'/groups/'+groupId,{})
    .then(response => {
      this.group=response;
      this.groupUpdated.next({...this.group});
    }
    ).catch(error => {
      console.log(error);
      this.router.navigate(['*']);
    });
  }

  getGroupObs(){
    return this.groupUpdated.asObservable();
  }

  getScans(sessionId,groupId){
    let error;
    API.get("MyAPI",'/sessions/'+sessionId+'/groups/'+groupId+'/scans',{})
    .then(response => {
      this.scans=response;
    }
    ).catch(err => {
      error=err;

    }).finally(()=>{
      if(error){
        this.scans=[{scan_id:null}];
      }
      groupIndex.push(groupId);
      this.scansUpdated.next([...this.scans]);
    });
  }

  getScansObs(){
    return this.scansUpdated.asObservable();
  }

  getGroupId(){
    //console.log(groupIndex);
    return groupIndex;
  }

  clear(){
    groupIndex=[];
    this.sessions=[];
    this.session=null;
    this.groups=[];
    this.group=null;
    this.scans=[];
    this.scan=null;
  }


  getScan(sessionId,groupId,scanId){
    API.get("MyAPI",'/sessions/'+sessionId+'/groups/'+groupId+'/scans/'+scanId,{})
    .then(response => {
      this.scan=response;
      this.scanUpdated.next({...this.scan});
    }
    ).catch(error => {
      console.log(error);
      this.router.navigate(['*']);
    });
  }

  getScanObs(){
    return this.scanUpdated.asObservable();
  }



}
