import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {LoginComponent} from '../dialogs/login/login.component';
import { AmplifyService }  from 'aws-amplify-angular';
import { API, Auth } from 'aws-amplify';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  signedIn:boolean=false;
  status="Login";

  //constructor(public dialog: MatDialog) { }
  constructor(
    public dialog: MatDialog,
    private amplifyService:AmplifyService,
    private router:Router
    ) {
      this.amplifyService.authStateChange$
          .subscribe(authState => {
              this.signedIn = authState.state === 'signedIn';
              this.status = this.signedIn?'Sign Out':'Login';
              //this.loginCheck();
      });
    }


    // use signedIn to display / hide more options buttons in header
    // get an idea of the data to be received and create graphs
    // Take every possible options from the old argiporter and turn them to graphs.
    // Make everything nice-- see maheenul.com background image
    // Use Gi-icons

    // Disable sign out popout after login


  ngOnInit() {
  }

  loginCheck(){
    //console.log(this.signedIn);
    if(this.signedIn){
      Auth.signOut()
       .then(data=>{
        this.router.navigate(['']);
       })
       .catch(err=>console.log(err));
    }else{
      this.loginOpen();
    }
  }


  loginOpen(){
    const dialogRef = this.dialog.open(LoginComponent, {
      data: {}
    })
    dialogRef.afterClosed().subscribe(text => {
      if(this.signedIn){
        this.router.navigate(['/dashboard']);
      }else{
        // stay
      }
    })
  }


}
