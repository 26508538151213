import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { EventEmitter, Output } from '@angular/core';
import { AmplifyService }  from 'aws-amplify-angular';
import { API, Auth } from 'aws-amplify';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  signedIn: boolean;
    user: any;
    greeting: string;
    jobs = null;

  constructor(public dialogRef: MatDialogRef<LoginComponent>,
    private amplifyService:AmplifyService,
    @Inject(MAT_DIALOG_DATA) public data:any) {
      this.amplifyService.authStateChange$
          .subscribe(authState => {
              this.signedIn = authState.state === 'signedIn';
              this.amplifyService.setAuthState


            // AFTER DB IS COMPLETE !
            //   API.get("agriporter",
            //   "/jobs", {})
            //   .then(response => {
            // this.jobs = JSON.parse(response.body)
            // console.log(this.jobs);
            //   }).catch(error => {
            // console.log(error.response)
            // this.jobs = null;
            //   });


              
              if (!authState.user) {
                  this.user = null;
                  console.log(this.user);
              } else {
                  this.user = authState.user;
                  this.greeting = "Hello dfgdfpogkpodfk " + this.user.username;
                  // console.log(this.user);
                  // console.log(this.amplifyService.analytics());
                  
              }
      });
  }

  ngOnInit() {

    



  }

  

}
