import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { AiService } from 'src/app/services/AI/ai.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router,ActivatedRoute } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { Subscription } from 'rxjs';
import { ConsoleLogger } from '@aws-amplify/core';
import * as moment from 'moment';

@Component({
  selector: 'app-session-page',
  templateUrl: './session-page.component.html',
  styleUrls: ['./session-page.component.scss'],
  animations: [

    trigger('slideFromBottom', [

      transition('void => *', [
        style({
          transform: 'translateY(30vh)',
          opacity:0,
        }),
        animate('600ms cubic-bezier(.99,0,.45,.71)')
      ])

    ]),
  ],
})
export class SessionPageComponent implements OnInit {

  constructor(
    public aiService:AiService,
    private sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    public router: Router,
  ) { }

  // http://gi.pages.internal.grahaminnovations.com/agriporter-api-ai-ovine/#/

/* Variables */
/////////////////////////////////////////////////////////////////////////

  sessionId;                // Session ID of the selected session
  session;                  // Session info of the current session
  groups;                   // Group array for all the groups in the selected session
  scans=[];                 // An array for the list of all the scans arranges group-wise
  bodyScore=[];             // body_condition_score for all the groups in the session
  fatScore=[];              // fat_score for all the groups in the session
  ovarianStatus=[0,0,0,0]   // Ovarian status sum for the session arranged in an array  //  i.e: [follicleSum,chSum,clSum,not_cyclingSum]
  uterineTone=[0,0,0]       // Uterine status sum for the session arranged in an array  //  i.e: [turgidSum,flaccidSum,abnormalSum]
  googleApiSrc;             // Contains the constructed google API for the location of the selected session
  showMap=false;            // Toggle for showing map on the google map on the browser
  isLoading=true;
  showBody = false;
  showFat = false;
  showOvarian = false;
  showUterine = false;
  isGroupData = false;
  showCharts = false;
  showBars = false;
  showMissingGroups=false;
  showMissingScans=false;
  private sessionSub:Subscription;
  private groupsSub:Subscription;
  private scansSub:Subscription;
  sessionTimeStamp;

  missingMessage = 'N/A';

  /* String labels for the different groups (required for bar-chart)*/
  /* Bar-Chart */
  groupLabels=[];
  labelX = 'Group'; // x-axis label for bar-chart
  labelY = 'Score'; // y-axis label for bar-chart

  /* Pie-Chart */
  ovarianLabel = [ 'FOLLICLE', 'CH', 'CL', 'NOT_CYCLING' ];
  uterineLabel = ['TURGID', 'FLACCID', 'ABNORMAL'];

  /* Common plots */
  public colorPallete = ["#73C04C","#DA6A2D","#fc7978","#c299fc","#b2fcff"];

/////////////////////////////////////////////////////////////////////////////////


/* Bar-Chart */
// barChartData -> Body Condition Score
// barChartData2 -> Fat Score

  public barChartOptions = {
    scaleShowVerticalLines: true,
    responsive: true,
    scales: {
      xAxes: [{
          ticks:{
            display:false
          },
          scaleLabel: {
            display: true,
            labelString: this.labelX
          }
      }],
      yAxes: [{
          ticks:{
            beginAtZero:true
          },
          scaleLabel: {
            display: true,
            labelString: this.labelY
          }
      }]
    },
    legend:{
      display:false
    },
    tooltips: {
       enabled: true
    },
    plugins: {
      datalabels: {
          display: false,
      }
    }
  };

  public barChartLabels = this.groupLabels;
  public barChartType = 'bar';
  public barChartLegend = false;
  public barColors = ["black"]; //removes the different color on hover
  public barChartData = [
  {data: this.bodyScore,backgroundColor: this.colorPallete[0], label: 'Body Condition'},
  ];
  public barChartData2 = [
    {data: this.fatScore,backgroundColor: this.colorPallete[1], label: 'Fat'},
  ];

//////////////////////////////////////////////////////////////////////////////////////


/* Pie Chart*/
// pieChartData -> Ovarian Status
// pieChartData2 -> Uterine Tone

public pieChartOptions: ChartOptions = {
  elements: {
    arc: {
      borderWidth: 0
    }
  },
  responsive: true,
  legend: {
    position: 'left',
  },
  plugins: {
    datalabels: {
      display:false,
      formatter: (value, ctx) => {
        const label = ctx.chart.data.labels[ctx.dataIndex];
        if(value){
          return label;
        }else{
          return null;
        }
      },
    },
  }
};
public pieChartType: ChartType = 'pie';
public pieChartLegend = true;
public pieChartPlugins = [pluginDataLabels];
public pieChartColors = [
  {
    backgroundColor: this.colorPallete
  },
];
public pieChartLabels = this.ovarianLabel;
public pieChartData: number[] =  this.ovarianStatus;
public pieChartLabels2 = this.uterineLabel;
public pieChartData2: number[] =  this.uterineTone;

/////////////////////////////////////////////////////////////////////////////////////////



  ngOnInit() {

    this.loadingTimeout();

    this.barChartLegend=true;

    // Selecting the session-id from the params
    this.sessionId=this.route.snapshot.params["session-id"];


    // Calling Service
    this.aiService.getSession(this.sessionId);
    this.sessionSub=this.aiService.getSessionObs()
    .subscribe(sessionData=>{
      this.session=sessionData;
      console.log(this.session);

    //Constructing Google API
    let sess = this.session.location;
    if(sess.country_code){
      this.googleApiSrc='https://maps.googleapis.com/maps/api/staticmap?size=512x256&maptype=roadmap&markers=size:mid%7Ccolor:red';
      this.googleApiSrc += '%7C'+sess.locality.replace(/\s/g, '+')+','+sess.region.replace(/\s/g, '+')+','+sess.postal_code+','+sess.country_code;
      this.googleApiSrc +='&key=AIzaSyDvPfyQY_JUXHP1Ax048eVVKth8URpwdm4';
      this.googleApiSrc = this.sanitize(this.googleApiSrc);
      this.showMap=true;
    }
    this.isLoading=false;


    // Service Call : GET GROUPS

    this.sessionTimeStamp =  moment.utc(this.session.created_at).format('DD-MM-YYYY  HH:mm:ss');

    this.aiService.getGroups(this.sessionId);
    this.groupsSub=this.aiService.getGroupsObs()
    .subscribe(groupsData=>{

      this.showMissingGroups = false;
      this.groups=groupsData;

      // No-groups error handling
      if(this.groups[0].group_id!==null){
        this.isGroupData = true;

        /* Temporary section until av_body_cond and av_fat are not in (GET_GROUPS) scanss is an array of an array of scans objects */
        let count =0;
        for (let i=0;i<this.groups.length;i++){

          //Promise.all()

          // Service Call : GET SCANS
          this.aiService.getScans(this.sessionId,this.groups[i].group_id);

        }

        this.scansSub=this.aiService.getScansObs()
        .subscribe(scansData=>{
          this.showMissingScans = false;
          let groupIds = this.aiService.getGroupId();
          let index=this.groups.findIndex(group=>group.group_id===groupIds[count])

          this.scans[index] =scansData;

          if(count===this.groups.length-1){
            this.initialisations()
          };
          count++;
        })

      }


    });

    });


  }


  ngOnDestroy(){
    (this.scansSub)?this.scansSub.unsubscribe():0;
    (this.groupsSub)?this.groupsSub.unsubscribe():0;
    (this.sessionSub)?this.sessionSub.unsubscribe():0;
    this.aiService.clear();
  }

  reloadData(){
    this.ovarianStatus=[0,0,0,0];
    this.uterineTone=[0,0,0];
    this.showMissingGroups=false;
    this.aiService.getSession(this.sessionId);
    this.loadingTimeout();
  }


  loadingTimeout(){
    setTimeout(()=>{

      console.log(this.groups);
      if(!this.isGroupData){
       this.showMissingGroups = true;
      }

      if(!this.showBars&&!this.showCharts&&this.isGroupData){
       this.showMissingScans = true;
      }
     }
      , 2000);
  }

  initialisations(){
    /* Part of temporary solution that makes an array for body+fat scores and distribution of ovarian_stat+uterine_tone */

    for (let i=0;i<this.groups.length;i++){
      let bodyConditionTotal = 0;
      let bodyConditionSum = 0;
      let fatSum = 0;
      let fatTotal = 0;
      if(this.scans[i][0].scan_id!==null){

        for (let j=0;j<this.scans[i].length;j++){

          // Summing body conditons and fat scores
          if(this.scans[i][j].body_condition_score){
            if(!this.showBody){
              this.showBars=true;
              this.showBody=true;
            }

            bodyConditionTotal+=parseFloat(this.scans[i][j].body_condition_score);
            bodyConditionSum++;
          }

          if(this.scans[i][j].internal_fat_score){
            if (!this.showFat){
              this.showBars = true;
              this.showFat=true;
            }
            fatTotal += parseFloat(this.scans[i][j].internal_fat_score);
            fatSum++;
          }


          /* Ovarian Status*/
          if(this.scans[i][j].ovarian_status){
            if(!this.showOvarian){
              this.showOvarian=true;
              this.showCharts=true;
            }
            switch(this.scans[i][j].ovarian_status){

              case 'FOLLICLE':{
                  this.ovarianStatus[0]++;
                break;
              }
              case 'CH':{
                this.ovarianStatus[1]++;
              break;
              }
              case 'CL':{
                this.ovarianStatus[2]++;
              break;
              }
              case 'NOT_CYCLING':{
                this.ovarianStatus[3]++;
              break;
              }
              case 'default':{
                break;
              }
            }
          }


          /* Uterine Tone*/
          if(this.scans[i][j].uterine_tone){
            if(!this.showUterine){
              this.showUterine=true;
              this.showCharts=true;
            }
            switch(this.scans[i][j].uterine_tone){

              case 'TURGID':{
                  this.uterineTone[0]++;
                break;
              }
              case 'FLACCID':{
                this.uterineTone[1]++;
              break;
              }
              case 'ABNORMAL':{
                this.uterineTone[2]++;
              break;
              }
              case 'default':{
                // Can be changed to unregistered
                break;
              }
            }
          }


        } //scan loop end

      } // checks if scan exists


      /* Body Condition & Fat */
      // this.bodyScore[i] = (bodyConditionSum>0) ? bodyConditionTotal/bodyConditionSum : null;
      // this.fatScore[i] = (fatSum>0) ? fatTotal/fatSum : null;
      this.bodyScore[i]=this.groups[i].average_body_condition_score;
      this.fatScore[i]=this.groups[i].average_internal_fat_score;
      this.groupLabels[i]=('Group-'+this.groups[i].semen_batch.sire_id)

    }//group loop end


    /* Strikeout pie chart when no data */
    if(!this.showUterine){
      for (let i=0; i<this.uterineTone.length; i++){
        if (this.uterineTone[i]===0){
          this.uterineTone[i]=undefined;
        }
      }
    }

    if(!this.showOvarian){
      for (let i=0; i<this.ovarianStatus.length; i++){
        if (this.ovarianStatus[i]===0){
          this.ovarianStatus[i]=undefined;
        }
      }
    }



    /* Removes the section from pie chart */
    if(this.showUterine){
      for (let i=0; i<this.uterineTone.length; i++){
        if (this.uterineTone[i]===0){
          this.uterineTone.splice(i,1);
          this.uterineLabel.splice(i,1);
          i=0;
        }
      }
    }

    if(this.showOvarian){
      for (let i=0; i<this.ovarianStatus.length; i++){
        if (this.ovarianStatus[i]===0){
          this.ovarianStatus.splice(i,1);
          this.ovarianLabel.splice(i,1);
          i=0;
        }
      }
    }


  }

  // refreshPlots(){
  //   this.barChartData = [
  //     {data: this.bodyScore,backgroundColor: this.colorPallete[0], label: 'Body Condition'},
  //     ];
  //   this.barChartData2 = [
  //       {data: this.fatScore,backgroundColor: this.colorPallete[1], label: 'Fat'},
  //     ];
  // }


  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);

  }


}

