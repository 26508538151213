import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { API, Auth } from 'aws-amplify';
import { AmplifyService }  from 'aws-amplify-angular';
import { RouterModule } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';




@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('flyInOut', [

      transition('void => *', [
        style({
          transform: 'translateY(-40vh)',
          opacity:0,
          width:'200px',
          height:'0px'

        }),
        animate('500ms cubic-bezier(.68,.16,.26,.93)')
      ])
    ])
  ]
})


export class DashboardComponent implements OnInit{

  ampService;
  // console.log(Auth);

  constructor(private amplifyService:AmplifyService) {

  }

  ngOnInit() {
    this.ampService=this.amplifyService;

  }


}

