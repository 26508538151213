import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { AiService } from 'src/app/services/AI/ai.service';
import { Router,ActivatedRoute } from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
//http://gi.pages.internal.grahaminnovations.com/agriporter-api-ai-ovine/#/
@Component({
  selector: 'app-scan-page',
  templateUrl: './scan-page.component.html',
  styleUrls: ['./scan-page.component.scss']
})
export class ScanPageComponent implements OnInit {

  
//////////////////////////////////////////////////////////////////////////

  constructor(
    public aiService:AiService,
    public route: ActivatedRoute,
    public router: Router,
  ) { 
  }



/////////////////////////////////////////////////////////////////////////
/* Variables */
//RENAME ALL VARS accordingly
  sessionId;                // Session ID of the selected session
  groupId;                  // Group ID of the selected group
  scanId;                   // Scans ID of the selected scan
  session;                  // Session info of the current session
  group;                    // Group info of the current group
  scan;                     // An array for containing all the scans of the selected group
  private scanSub:Subscription
  private sessionSub:Subscription
  private groupSub:Subscription

  isLoading=true;           // Toggle for rendering the html contents
  missingMessage = 'N/A';
  scanTime;
  createdTimeStamp;
  breadcrumbScan;



  ngOnInit() {
    
    // Selecting the session-id from the params
    // Remove the parseInt() if not required
    this.sessionId=this.route.snapshot.params["session-id"];


    // Selecting the group-id from the params
    this.groupId=this.route.snapshot.params["group-id"];


    // Selecting the group-id from the params
    this.scanId=this.route.snapshot.params["scan-id"];



    // HTTP CALL HERE (GET_SESSION) and (GET_GROUP) getSession(this.sessionId) getGroup(this.groupId)
    this.aiService.getSession(this.sessionId)
    this.sessionSub = this.aiService.getSessionObs()
    .subscribe(sessionData=>{
      this.session = sessionData;

      this.aiService.getGroup(this.sessionId,this.groupId)
      this.groupSub = this.aiService.getGroupObs()
      .subscribe(groupData=>{
        this.group = groupData;

        this.aiService.getScan(this.sessionId,this.groupId,this.scanId);
        this.scanSub=this.aiService.getScanObs()
        .subscribe(scanData=>{
          this.scan = scanData;
          console.log(this.scan);
          this.isLoading=false;
          this.scanTime = moment(this.scan.ewe_rfid_scanned_at,'LTS').format('HH:mm:ss');
          this.createdTimeStamp = moment.utc(this.scan.created_at).format('DD-MM-YYYY  HH:mm:ss');
          this.breadcrumbScan = moment(this.scan.ewe_rfid_scanned_at,'LTS').format('HH:mm');
        })
      })
    })


  }

  ngOnDestroy(){
    this.sessionSub?this.sessionSub.unsubscribe():0;
    this.groupSub?this.groupSub.unsubscribe():0;
    this.scanSub?this.scanSub.unsubscribe():0;
    this.aiService.clear();
  }



}













