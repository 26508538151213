import { Component, OnInit } from '@angular/core';
//import { AmplifyService }  from 'aws-amplify-angular';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('fadeIn', [

      transition('void => *', [
        style({ 
          opacity:0,
        
        }),
        animate(800)
      ])
    ]),
    trigger('fadeInSlide', [

      transition('void => *', [
        style({ 
          opacity:0,
          transform: 'translateX(-40vw)',
        
        }),
        animate('600ms cubic-bezier(1,-0.01,.85,.71)')
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {

  constructor( ) {}

  ngOnInit() {
  }

}
