import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {FlexLayoutModule} from '@angular/flex-layout';
import { DashboardComponent } from './dashboard/dashboard.component';

import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';

import { ChartsModule } from 'ng2-charts';
import { LoginComponent } from './dialogs/login/login.component';
import {MatDialogModule} from '@angular/material/dialog';
import { AuthGuard } from './auth/auth.guard';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator'
import {MatSortModule} from '@angular/material/sort';
import {MatFormFieldModule} from '@angular/material/form-field';
import { SessionHistoryComponent } from './AI/session-history/session-history.component';
import { SessionPageComponent } from './AI/session-page/session-page.component';
import { HttpClientModule} from '@angular/common/http';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import { GroupPageComponent } from './AI/group-page/group-page.component';
import { ScanPageComponent } from './AI/scan-page/scan-page.component';
import { PageNotFoundComponent } from './AI/page-not-found/page-not-found.component';


// import { Provider } from '@angular/core';
// import { BrowserXhr } from '@angular/common/http';
// import {CustExtBrowserXhr} from './cust-ext-browser-xhr';

// Make sure to import and add MAT COMPONENTS (API) as required

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    DashboardComponent,
    LoginComponent,
    SessionHistoryComponent,
    SessionPageComponent,
    GroupPageComponent,
    ScanPageComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    [FlexLayoutModule],
    AmplifyAngularModule,
    ChartsModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    HttpClientModule,
    MatCardModule,
    MatExpansionModule
  ],
  providers: [
    AmplifyService,
    //AuthGuard
  ],
  entryComponents: [
    LoginComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
