import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SessionHistoryComponent } from './AI/session-history/session-history.component';
import { SessionPageComponent } from './AI/session-page/session-page.component';
import { GroupPageComponent } from './AI/group-page/group-page.component';
import { ScanPageComponent } from './AI/scan-page/scan-page.component';
import { PageNotFoundComponent } from './AI/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    //canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    pathMatch: 'full'
  },{
    path:'ovine/ai/sessions/:session-id/groups/:group-id/scans/:scan-id',
    component:ScanPageComponent,
    pathMatch:'full'
  },{
    path:'ovine/ai/sessions/:session-id/groups/:group-id',
    component:GroupPageComponent,
    pathMatch:'full'
  },{
    path:'ovine/ai/sessions/:session-id',
    component:SessionPageComponent,
    pathMatch:'full'
  },{
    path:'ovine/ai/sessions',
    component:SessionHistoryComponent,
    pathMatch:'full'
  },{
    path:'**',
    component:PageNotFoundComponent,
    pathMatch:'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
